import React from "react";
import { graphql } from "gatsby";
import { Home, PageTemplateWrapper } from "@resourcehub/resourcehub-components";
import { resourceServices } from "@resourcehub/resourcehub-services";
import configGenerator from "@resourcehub/resourcehub-configuration";
import "../../styles/main.less";

const Page = ({ data }) => {
    const { copy, items, page, site } = data;
    const featuredItemIds = ["recKH4erDob2cWMnU", "rec4Va9abOvfG5AFb", "recJe5WnAvXaEh3wr", "rec7EKOIAY17IPogQ"];
    const filteredItems = items.edges
        .filter((item) => featuredItemIds.includes(item.node.recordId))
        .sort(resourceServices.resourcesTitleSort);
    const config = configGenerator({
        market: site.siteMetadata.market,
        site: "enterprise",
        locale: site.siteMetadata.lang,
        page: "cn-enterprise-home"
    });
    return (
        <PageTemplateWrapper config={config}>
            <Home
                copyData={copy.data}
                data={data}
                domain={site.siteMetadata.legacy.domain}
                items={filteredItems}
                pageData={page.data}
                vertical="business"
                hideVerticals={true}
            />
        </PageTemplateWrapper>
    );
};

export default Page;

export const pageQuery = graphql`
    query {
        copy: airtable(data: { title: { eq: "cn-copy" } }) {
            ...PagesCopyFragment
        }
        items: allAirtable(filter: { table: { eq: "Items" } }) {
            edges {
                node {
                    ...ItemsFragment
                }
            }
        }
        page: airtable(data: { title: { eq: "cn-corp" } }) {
            ...PagesHomeFragment
        }
        site {
            ...SiteFragment
        }

        ## IMAGES
        section_1_img_1: file(relativePath: { eq: "images/section-1-img-1.png" }) {
            childImageSharp {
                fixed(width: 1034, height: 586) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 1034, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        iphone_blue: file(relativePath: { eq: "images/iphone-blue.png" }) {
            childImageSharp {
                fixed(width: 938, height: 622) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 938, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        empty_iphone: file(relativePath: { eq: "images/empty-iphone.png" }) {
            childImageSharp {
                fixed(width: 785, height: 450) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 785, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        tutor_screen: file(relativePath: { eq: "images/tutor-screen.png" }) {
            childImageSharp {
                fixed(width: 778, height: 523) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 778) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        section_5_img_1: file(relativePath: { eq: "images/section-5-img-1.png" }) {
            childImageSharp {
                fixed(width: 1068, height: 696) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 1068) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        iphonex_24: file(relativePath: { eq: "images/iphonex-24.png" }) {
            childImageSharp {
                fixed(width: 1000, height: 462) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        iphonex_engaging: file(relativePath: { eq: "images/iphonex-engaging.png" }) {
            childImageSharp {
                fixed(width: 1000, height: 462) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        iphonex_immersion: file(relativePath: { eq: "images/iphonex-immersion.png" }) {
            childImageSharp {
                fixed(width: 1000, height: 462) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        iphonex_mobile: file(relativePath: { eq: "images/iphonex-mobile.png" }) {
            childImageSharp {
                fixed(width: 1000, height: 462) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        iphonex_truaccent: file(relativePath: { eq: "images/iphonex-truaccent.png" }) {
            childImageSharp {
                fixed(width: 1000, height: 462) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        iphonex_tutoring: file(relativePath: { eq: "images/iphonex-tutoring.png" }) {
            childImageSharp {
                fixed(width: 1000, height: 462) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        screen_assessment: file(relativePath: { eq: "images/screen-assessment.png" }) {
            childImageSharp {
                fixed(width: 704, height: 405) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 704) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        screen_implementation: file(relativePath: { eq: "images/screen-implementation.png" }) {
            childImageSharp {
                fixed(width: 704, height: 405) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 704) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        screen_placement: file(relativePath: { eq: "images/screen-placement.png" }) {
            childImageSharp {
                fixed(width: 704, height: 405) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 704) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        screen_reporting: file(relativePath: { eq: "images/screen-reporting.png" }) {
            childImageSharp {
                fixed(width: 704, height: 405) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 704) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        screen_testing: file(relativePath: { eq: "images/screen-testing.png" }) {
            childImageSharp {
                fixed(width: 704, height: 405) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 704) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        screen_training: file(relativePath: { eq: "images/screen-training.png" }) {
            childImageSharp {
                fixed(width: 704, height: 405) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 704) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        ## PRODUCT-SAMPLE
        azulesPorUnDia: file(relativePath: { eq: "product-sample/azulesPorUnDia.png" }) {
            childImageSharp {
                fixed(width: 185, height: 185) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 186) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bicicletaAmarilla: file(relativePath: { eq: "product-sample/bicicletaAmarilla.png" }) {
            childImageSharp {
                fixed(width: 185, height: 185) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 186) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bicicletaAzul: file(relativePath: { eq: "product-sample/bicicletaAzul.png" }) {
            childImageSharp {
                fixed(width: 185, height: 185) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 186) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        dosBicicletasAzules: file(relativePath: { eq: "product-sample/dosBicicletasAzules.png" }) {
            childImageSharp {
                fixed(width: 185, height: 185) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 186) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        dosBicicletasDiferentes: file(relativePath: { eq: "product-sample/dosBicicletasDiferentes.png" }) {
            childImageSharp {
                fixed(width: 185, height: 185) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 186) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        verdesPorUnDia: file(relativePath: { eq: "product-sample/verdesPorUnDia.png" }) {
            childImageSharp {
                fixed(width: 185, height: 185) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 186) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        ## VERTICALS
        k12_circle: file(relativePath: { eq: "verticals/education-primary-circle.png" }) {
            childImageSharp {
                fixed(width: 300, height: 300) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 300, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        hed_circle: file(relativePath: { eq: "verticals/education-secondary-circle.png" }) {
            childImageSharp {
                fixed(width: 300, height: 300) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 300, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        business_circle: file(relativePath: { eq: "verticals/enterprise-circle.png" }) {
            childImageSharp {
                fixed(width: 300, height: 300) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 300, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        pub_circle: file(relativePath: { eq: "verticals/publicsector-circle.png" }) {
            childImageSharp {
                fixed(width: 300, height: 300) {
                    ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 300, quality: 80) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
